import { Navbar, Nav, NavDropdown, FormControl, Form } from 'react-bootstrap';
import Link from 'next/link'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from "next-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './Header.module.scss';
import hooks from "./HeaderHook";
import NotificationBlock from './../NotificationBlock';
import ActiveLink from "../../components/Link/Link";
import SelectOptions from "../../components/TextInput/SelectOption";
import { get_menu } from '../../store/actions/general';

function Header(props) {
    const { data, canUseDOM, activeSearch, setActiveSearch, handleClick, } = hooks(props);
    const { t } = useTranslation();
    const language = props.language ? props.language : 'fr';
    const languages = useSelector(state => state.language);
    const settings = useSelector(state => state.settings);
    const menu = useSelector(state => state.menu)
    const [lang, setLanguage] = useState("fr");
    const router = useRouter();
    const dispatch = useDispatch();
    const settings_post = (data) => {
        dispatch(
            settings_saga({ ...data, add: !settings.data.id ? true : false })
        );
    };
    const select_language = (id) => {
        id === 1 ? setLanguage("fr") : setLanguage("us");
        i18n.changeLanguage(lang);
        if (id === 1) {
            window.location = window.location.href.includes('/en/')
                ? window.location.href.replace('/en/', `/fr/`)
                : window.location.href.replace('/ru/', `/fr/`);
        }
        settings_post({
            ...settings.data[0],
            "language": value,
            user: props.user_id
        });
    };
    const useStyles = makeStyles(theme => ({
        root: {
            color: theme.palette.primary,
        }
    }));
    useEffect(() => {
        dispatch(get_menu())
    }, [router.query])
    const classes = useStyles();
    return (
        <div className="sticky-top w-100 bg-white">
            <header className={styles.main_header}>
                <Navbar
                    className="container d-flex justify-content-center justify-content-md-between align-items-end container">
                    <Navbar.Brand className="p-0">
                        <img onClick={() => handleClick(`/${props?.language}`)} className={styles.header_logo} src="/logo.svg" />
                    </Navbar.Brand>
                    <Nav className="d-none d-sm-flex align-items-sm-center">
                        {/* <ActiveLink href={`/new`} as={`/new`} activeClassName={styles.top_link_active}>
                            <a className={styles.menu_item}>{t("landingLabel")}</a>
                        </ActiveLink>
                        <ActiveLink href={`/${language}/help`} as={`/${language}/help`} activeClassName={styles.top_link_active}>
                            <a className={styles.menu_item}>{t("helpLabel")}</a>
                        </ActiveLink> */}
                        <div ref={(ref) => {
                            if (ref) {
                                ref.innerHTML = menu?.results ? menu?.results[0][`white_header_${props?.language}`] : "";
                            }
                        }}></div>
                        <div className={`start-select ${styles.sub_header_item} ${styles.start_language}`}>
                            <SelectOptions
                                inputProps={{
                                    name: 'language',
                                }}
                                language={props.language?.toUpperCase()}
                                value={props.language}
                                list={languages.results}
                                handleChange={(e) => {
                                    let value = e.target.value;
                                    select_language(value);
                                }} />
                            {/* </Typography> */}
                            {/* <img className={styles.world} src='/images/world.png' /> */}
                        </div>
                    </Nav>


                </Navbar>
            </header>

            <Navbar className={styles.sub_header} expand="md">
                <div className="container">
                    <Navbar.Toggle aria-controls="navbarSupportedContent" className="p-0 border-0 text-white" />
                    <Navbar.Collapse id="navbarSupportedContent" className="align-items-center">

                        <Nav className="mr-auto extended">
                            <Navbar.Toggle aria-controls="navbarSupportedContent" className="p-0 border-0 text-white ofCanvas">
                            </Navbar.Toggle>
                            <div className="p-0 d-none d-sm-flex flex-column h-content" ref={(ref) => {
                            if (ref) {
                                ref.innerHTML = menu?.results ? menu?.results[0][`pink_header_${props?.language}`] : "";
                            }
                        }}></div>
                            {/* <ActiveLink activeClassName={styles.active} as={`/${language}`} href={`/${language}`}>
                                <a className={styles.sub_header_item}>{t("main")}</a>
                            </ActiveLink>
                            <ActiveLink activeClassName={styles.active} as={`/${language}/service`} href={`/${language}/service`}>
                                <a className={styles.sub_header_item}>{t("servicesLabel")}</a>
                            </ActiveLink> */}
                            <div className="p-0 d-sm-none d-flex flex-column h-content" ref={(ref) => {
                            if (ref) {
                                ref.innerHTML = menu?.results ? menu?.results[0][`drawer_${props?.language}`] : "";
                            }
                        }}>
                                {/* <ActiveLink activeClassName={styles.top_link_active} as={`/${language}/settings`} href={`/${language}/settings`}>
                                    <a className={styles.sub_header_item}>{t("settingsLabel")}</a>
                                </ActiveLink>
                                <ActiveLink activeClassName={styles.top_link_active} as={`/${language}/help`} href={`/${language}/help`}>
                                    <a className={styles.sub_header_item}>{t("helpLabel")}</a>
                                </ActiveLink>
                                <ActiveLink activeClassName={styles.top_link_active} as={`/${language}/new`} href={`/${language}/new`}>
                                    <a className={styles.sub_header_item}>{t("landingLabel")}</a>
                                </ActiveLink> */}
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand className="d-flex d-md-none p-0">
                        <img onClick={() => handleClick(`/`)} className={styles.header_logo} src="/Beautycils_white.png" />
                    </Navbar.Brand>
                    <Nav className="d-flex align-items-center flex-row">
                        <ActiveLink activeClassName={`${styles.link_active}`} href={`/${language}/my-profile`} >
                            <a className={`overflow-hidden d-block p-0 ${styles.my_profile_img}`}>
                                <img src={props.user_image ? props.user_image : "/profile-user-w.svg"} className={styles.header_profile} alt="" />
                            </a>
                        </ActiveLink>
                    </Nav>
                </div>
            </Navbar>
        </div>
    );
}

export default Header;
