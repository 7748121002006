import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_footer } from '../../store/actions/my-info';
import styles from './Footer.module.scss';
function Footer(props) {
  const dispatch = useDispatch();
  const footer = useSelector((state) => state.settings?.footer);
  const router = useRouter();
  useEffect(() => {
    dispatch(get_footer({ token: props.token }));
  }, [router.query]);
  return (
    <div className={styles.footer}>
      <div className='container m-auto d-flex justify-content-between'>
        <div ref={(ref) => {
          if (ref) {
            ref.innerHTML = footer?.results ? footer?.results[0][`first_footer_${props?.language}`] : "";
          }
        }}></div>
        <div ref={(ref) => {
          if (ref) {
            ref.innerHTML = footer?.results ? footer?.results[0][`second_footer_${props?.language}`] : "";
          }
        }}></div>
        <div ref={(ref) => {
          if (ref) {
            ref.innerHTML = footer?.results ? footer?.results[0][`third_footer_${props?.language}`] : "";
          }
        }}></div>
      </div>
    </div>
  );
}

export default Footer;
