import styles from './NotificationBlock.module.scss';
import hooks from "./NotificationHook";
import NotificationBlockItem from './NotificationBlockItem';
import { useTranslation } from "next-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';

function NotificationBlock(props) {
    const { today, data, translate } = hooks(props);
    const { t } = useTranslation();
    return (
        data.results
            ? <div className={styles.notification_cont}>
                {data.results.filter((item) => today(item.created_at) === "today").length !== 0 ?
                    <h3 className={styles.notification_title}>{t("today")}</h3> : null}
                {data.results.filter((item) => today(item.created_at) === "today").map((item, index) => {
                    return <NotificationBlockItem key={index} item={item} create_at={moment(item.created_at).startOf('hour').fromNow()} />
                })}
                {data.results.filter((item) => today(item.created_at) === "yesterday").length !== 0 ?
                    <h3 className={styles.notification_title}>{t("yesterday")}</h3> : null}
                {data.results.filter((item) => today(item.created_at) === "yesterday").map((item, index) => {
                    return <NotificationBlockItem key={index} item={item} create_at={moment(item.created_at).format('d MMM')} />
                })}
                {data.results.filter((item) => today(item.created_at) === "this_month").length !== 0 ?
                    <h3 className={styles.notification_title}>{t("yesterday")}</h3> : null}
                {data.results.filter((item) => today(item.created_at) === "this_month").map((item, index) => {
                    return <NotificationBlockItem key={index} item={item} create_at={moment(item.created_at).format('d MMM')} />
                })}
                {data.results.filter((item) => today(item.created_at) === "past").length !== 0 ?
                    <h3 className={styles.notification_title}>{t("earlier")}</h3> : null}
                {data.results.filter((item) => today(item.created_at) === "past").map((item, index) => {
                    return <NotificationBlockItem create_at={moment(item.created_at).format('d MMM')} key={index} item={item} />
                })}
                {data.results.length === 0
                    ? <div className='w-100 h-100 d-flex justify-content-center align-item-center'>
                        {t('no_notifications')}</div>
                    : null}
            </div>
            : <div className='w-100 d-flex flex-column align-items-center'><ClipLoader color="#464646" size={15} /></div>
    );
}

export default NotificationBlock;
