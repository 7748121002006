import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Category from "../../services/api/general";
import { get_language_saga } from '../../store/actions/general';
const HeaderHook = (props) => {
  const [data, setData] = useState({});
  const [activeSearch, setActiveSearch] = useState(false);
  const languages = useSelector(state => state.language);
  let canUseDOM = !!(
    (typeof window !== 'undefined' &&
      window.document && window.document.createElement)
  );
  const dispatch = useDispatch();
  const get_notification = async () => {
    const result = await Category.get_notification_count(props.token);
    setData(result);
  };
  const router = useRouter();
  const handleClick = (href) => {
    router.push(href);
  };
  const translate = useSelector(state => state.translate);
  const my_info = useSelector(state => state.edit);
  // const handleScroll = (e) => {
  //   if (e.target.clientHeight - e.target.scrollTop !== e.target.scrollHeight) return;
  //   if (data.results.length < data.count) {
  //     get_notification({ offset: data.results.length + 1, page: (data.results.length / 10) + 1 })
  //   }
  // };
  // const today = (date) => {
  //     var date_now = new Date();
  //     var date_item = new Date(date);
  //     var d_new = date_now.getDate();
  //     var d = date_item.getDate();
  //     if (d === d_new) {
  //         return "today"
  //     }
  //     if (d === d_new - 1) {
  //         return "tomorrow"
  //     }
  //     if (d < d_new - 1) {
  //         return "past"
  //     }
  // }
  // const tomorrow = (date) => {
  //     var date_now = new Date();
  //     var date_item = new Date(date);
  //     var d_new = date_now.getDay();
  //     var d = date_item.getDay();
  //     if (d === d_new - 1) {
  //         return true
  //     } else {
  //         return false
  //     }
  // }
  useEffect(() => {
    if (!languages.results) {
      dispatch(get_language_saga());
    }
    // const interval = setInterval(() => {
    //   get_notification();
    // }, 10000);
    // return () => clearInterval(interval);
  }, [languages]);
  return { data, my_info, translate, activeSearch, canUseDOM, setActiveSearch, handleClick };
};
export default HeaderHook;